<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid justify-content-center py-3 mt-10 mb-8 rounded bg-white"
    >
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Validation de votre email
      </h3>
    </div>
    <div class="row">
      <div class="col">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <h2 class="card-title text-center">Validation Email</h2>
          </div>
          <div class="card-body">
            <div>
              <sweetalert-icon
                icon="success"
                v-if="validationStatus === 1"
              ></sweetalert-icon>
              <sweetalert-icon
                icon="info"
                v-if="validationStatus === 2"
              ></sweetalert-icon>
              <sweetalert-icon
                icon="error"
                v-if="validationStatus === 0"
              ></sweetalert-icon>
              <p class="mt-4 text-center">
                {{ playerInfo.first_name }} {{ playerInfo.last_name }} votre
                email {{ playerInfo.email }}
                <span v-if="validationStatus === 1">a été bien activé</span>
                <span v-if="validationStatus === 2">a été déjà activé</span>
                <span v-if="validationStatus === 0"
                  >n'a pas été validé.<br />{{ erroMsg }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SweetalertIcon from "vue-sweetalert-icons";

export default {
  name: "EmailValidation",
  components: {
    SweetalertIcon
  },
  data() {
    return {
      validationStatus: -1,
      playerInfo: {
        email: "",
        firs_tname: "",
        last_name: ""
      },
      validEmail: false,
      erroMsg: ""
    };
  },
  beforeCreate() {
    console.log(this.$route.params);
    const payload = { token: this.$route.params.token };
    console.log(payload);
    ApiService.post("email-validate", payload)
      .then(({ data }) => {
        console.log(data);
        if (data.status === 1) {
          // Activation is ok
          this.validationStatus = 1;
          this.playerInfo = data.info;
        } else if (data.status === 2) {
          // Already activted
          this.validationStatus = 2;
          this.playerInfo = data.info;
        } else {
          this.validationStatus = 0;
          this.validEmail = false;

          this.erroMsg = data.Error;
          if (this.erroMsg === "Malformed UTF-8 characters") {
            this.erroMsg =
              "Token non valide. Veuillez contacter l'administrateur du jeu ";
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style scoped></style>
